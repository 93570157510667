angular.module('cpformplastApp')
  .controller('SubjobScheduleEdit', function ($scope, Util, Notification, DataManager, $modalInstance, subjobSchedule, editable) {

    $scope.editable = editable !== undefined ? editable : true;
    $scope.minDate = new Date();
    $scope.jobHistory = null;
    $scope.subjobSchedule = subjobSchedule;
    $scope.formattedStartDate = Util.getDateTimeString(subjobSchedule.startDate, true);
    $scope.formattedEndDate = Util.getDateTimeString(subjobSchedule.endDate, true);
    $scope.formattedDeliveryDate = Util.getDateString(subjobSchedule.subjob.date, true);
    DataManager.fetchSubjobSchedules('history', null, subjobSchedule.subjob.job.item.id).then((result) => {
      $scope.jobHistory = result.map(schedule => schedule.getJson($scope.scheduleTableStructure));
    }
  )

    $scope.scheduleTableStructure = [{
      'title':'Job',
      'value':'job',
      'key':'job',
      'filter':'alpha'
    },{
      'title':'Moule',
      'value':'mold',
      'key':'mold',
      'filter':'alpha'
    },{
      'title':'Client',
      'value':'client',
      'key':'client',
      'filter':'alpha'
    },{
      'title':'QTÉ.',
      'value':'producedQuantity',
      'key':'producedQuantity',
      'filter':'none'
    },{
      'title':'Début',
      'value':'start',
      'key':'start',
      'filter':'datetime'
    },{
      'title':'Fin',
      'value':'end',
      'key':'end',
      'filter':'datetime'
    },{
      'title':'Vendeur',
      'value':'salesman',
      'key':'salesman',
      'filter':'alpha'
    }];

    $scope.hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

    $scope.setStartingHour = function(hour) {
      $scope.subjobSchedule.startDate.setHours(hour);
    };

    $scope.save = function() {
      DataManager.updateSubjobSchedule($scope.subjobSchedule)
      .then(machines => {
        $scope.$dismiss();
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu');
      });
    };

  });
